.box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: white;
    color: black;
    border: 0.1px solid #adb5bd;
    font-size: 0.9rem;
    font-weight: 400;
    position: absolute;
    z-index: 9;
    width: 30vw;
    height: 30vh;

    @media only screen and (max-width: 800px) {
        width: 73vw;
    }

    .column-one {
        border-right: 0.1px solid #adb5bd;
    }

    .file-types-list {

        td {
            cursor: pointer;
            border-bottom: 0.1px solid #adb5bd;
            padding: 0 0.5rem;

            &:hover {
                background-color: #e5e5e5;
                font-weight: 500;
            }
        }

    }

    .column-two {
        overflow-y: scroll;

        .search-bar-box {

            .input-box {
                height: 100%;
                width: 100%;
                margin-top: 0.4rem;
                border: none;
                font-size: 0.9rem;
                color: #333533;

                &:focus {
                    outline: none;
                }


            }
        }

        .pill-button {
            background: none;
            border: 0.1px solid #adb5bd;
            border-radius: 10%;
            font-size: 0.8rem;
            padding: 0.5rem;
            margin: 0.15rem;

            &:hover {
                background-color: #e5e5e5;
                font-weight: 500;
            }

            &:focus
             {
                color: white;
                background-color: #c1121f;
            }

        }
        
    }
}