
.menu-container {
    display: flex;
    color: white;
    padding: 2rem 0;
    font-weight: 500;
    font-size: 1.1rem;
    flex-direction: column;
    justify-content: space-between;
    min-height: 20vh;


    @media only screen and (max-width: 800px) {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 32vh;
        padding-top: 1.2rem;
        font-weight: 400;
        font-size: 0.8rem;
    }

    .menu-item {

        &:hover {
            font-weight: 600;
        }
        
        @media only screen and (max-width: 800px) {
            margin-top: 0.6rem;
        }
    }

    .type-text {
        margin-left: 0.8rem;
    }

    .first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 5rem;

        @media only screen and (max-width: 800px) {
            flex-direction: column; 
            align-items: baseline;
        }
    }

    .second-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 5rem;

        @media only screen and (max-width: 800px) {
            flex-direction: column;
            align-items: baseline;
        }
    }
}

.main-container {
    display: flex;
    flex-direction: column;
    color: white;

    .formats-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        align-self: center;
        width: 100vw;

        .file-type-heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            font-size: 1.2rem;
            padding: 1.5rem 0;

            .file-type-heading-text {
                margin-left: 0.6rem;
            }
        }

        .file-types-list {
            display: grid !important;
            grid-template-columns: auto auto auto auto auto auto auto auto auto !important;
            column-gap: 0.5rem;
            row-gap: 0.2rem;

            @media only screen and (max-width: 800px) {
                grid-template-columns: auto auto !important;
            }

            @media only screen and (min-width: 801px) and (max-width: 1024px) {
                grid-template-columns: auto auto auto auto !important;
            }

            .item {
                font-weight: 500 !important;
                font-size: 1rem !important;

                &:hover {
                    font-weight: 600;
                }
            }
        }
    }

    .banner {
        min-height: 40vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 800px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        .first-column {
            display: flex;
            max-width: 45vw;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 2rem;

            @media only screen and (max-width: 800px) {
                max-width: 80vw;
                margin-top: 1rem;
                padding: 0 2rem;
            }
        }

        .second-column {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: flex-start;
                max-width: 45vw;
    
                @media only screen and (max-width: 800px) {
                    max-width: 70vw !important;
                    margin-top: 1rem;
                    padding-left: 1.5rem !important;
                    margin-left: 0.5rem;
                }


                .dropdown-list {
                    margin-right: 2rem;
                    width: 5.5rem;
                    height: 2rem;
                    background: none;
                    border: 0.05rem solid white;
                    color: white;
                    cursor: pointer;

                    &:hover {
                        border: 0.1rem solid white;
                    }
    
                    @media only screen and (max-width: 800px) {
                        min-width: 4.8rem !important;
                        display: flex;
                        flex-direction: row;
                    }

                    .small-text {
                        font-size: 0.8rem;
                    }
                }
                
        }

        .file-type {
            font-size: 1.4rem;
            font-weight: 700;

            @media only screen and (max-width: 800px) {
                font-size: 1.2rem !important;
            }
        }

        .banner-text {
            font-size: 1rem;
            min-width: 45vw;
            font-weight: 500;
            text-align: justify;
            text-justify: inter-character;

            @media only screen and (max-width: 800px) {
                font-size: 1rem !important;
            }
        }
    }

    .file-upload-section {
        margin-top: 1rem;
        align-self: center;
    }
}