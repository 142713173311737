.upload-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    padding: 1rem 0;
    color: black;
    min-height: 35vh;
    border-top: 1px dotted #bfc0c0;
    border-bottom: 1px dotted #bfc0c0;

    .converted-file {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 55vw;

        .single-file-completed {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            min-width: 55vw;
            padding: 0 1.7rem;
            color: black;
            height: 2.7rem;
            border-radius: 2rem;
            border: 0.5px solid #343a40;

            @media only screen and (max-width: 800px) {
                min-width: 80vw;
            }

            .download-and-x {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .finished-text {
                    margin-right: 2rem;
                    padding: 5px;
                    font-size: 0.7rem;
                    color: white;
                    border-radius: 0.2rem;
                    background-color: #28a745;
                    max-height: 2rem;

                    @media only screen and (max-width: 800px) {
                        min-width: 4rem;
                        margin-right: 0.5rem;
                    }
                }

                .x-icon {
                    color: red;
                    margin-left: 0.8rem;
                    height: 0.8rem;
                    cursor: pointer;

                    @media only screen and (max-width: 800px) {
                        margin-left: 0.4rem;
                    }
                }
            }

            .download-button {
                margin: 0 0.5rem;
                cursor: pointer;
                background-color: #28a745;
                border-radius: 2px;
                border: none;
                font-size: 0.8rem;
                font-weight: 600;
                color: #212529;
                width: 6rem;
                height: 1.8rem;
                -webkit-box-shadow: 1px 1px 5px 0px #004b23;
                -moz-box-shadow: 1px 1px 5px 0px #004b23;
                box-shadow: 1px 1px 5px 0px #004b23;

                @media only screen and (max-width: 800px) {
                    width: 5rem;
                    height: 2rem;
                    font-size: 0.7rem;
                    padding: 2px;
                }
            }


            .filename-and-finishedPill {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
            }


        }

        .error-message-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 2.7rem;
            min-width: 55vw;
            padding: 0 1.7rem;
            border-radius: 2rem;
            border: 0.5px solid #343a40;
            font-size: 1rem;
            font-weight: 500;
            color: #495057;

            @media only screen and (max-width: 800px) {
                min-width: 82vw;
                font-size: 0.8rem
            }

            .message-icon {
                color: #e5383b
            }
        }

        .file-types-position {
            margin-right: auto;
            margin-bottom: 2%;

            @media only screen and (max-width: 900px) {
                min-width: 100vw;
                margin-left: 4rem;
                margin-bottom: 12rem;

            }

            @media only screen and (min-width: 901px) and (max-width: 1369px) {
                margin-right: auto;
                min-width: 100vw;
                margin-left: 72%;
                margin-bottom: 15%;
            }
        }

        .progress-text {
            margin-right: 1rem;
        }
    }


    .files-and-button {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0 1.7rem;
        color: black;
        height: 3rem;
        -webkit-box-shadow: 0px 1px 3px 1px rgba(73, 80, 87, 0.2);
        -moz-box-shadow: 0px 1px 3px 1px rgba(73, 80, 87, 0.2);
        box-shadow: 0px 1px 3px 1px rgba(73, 80, 87, 0.2);

        .single-file {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 55vw;
            justify-content: space-between;

            @media only screen and (max-width: 800px) {
                width: 80vw;
            }

            .drop-icon-cross {
                cursor: pointer;
            }

            .file-name {
                font-size: 1rem;
                font-weight: 500;

                @media only screen and (max-width: 800px) {
                    font-size: 0.9rem
                }
            }

            .single-convert-button {
                margin: 0 0.5rem;
                cursor: pointer;
                background-color: #f6bd60;
                border-radius: 2px;
                border: none;
                font-size: 0.8rem;
                font-weight: 600;
                color: #212529;
                width: 6rem;
                height: 1.8rem;
                -webkit-box-shadow: 1px 1px 5px 0px #6c757d;
                -moz-box-shadow: 1px 1px 5px 0px #6c757d;
                box-shadow: 1px 1px 5px 0px #6c757d;
            }

        }
    }

    .upload-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        background-color: #f6bd60;
        border-radius: 4px;
        font-size: 1rem;
        font-weight: 700;
        color: #212529;
        // border: 3px solid #023e8a;
        width: 10rem;
        height: 3.5rem;
        cursor: pointer;
        -webkit-box-shadow: 1px 1px 5px 0px #6c757d;
        -moz-box-shadow: 1px 1px 5px 0px #6c757d;
        box-shadow: 1px 1px 5px 0px #6c757d;
    }

    .drop-text {
        font-size: 1rem;
        color: #495057;
    }

    .drop-icon {
        margin-right: 0.5rem;
    }
}