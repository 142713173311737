.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100vw;
    border-bottom: 1px dotted #bfc0c0;


    .brand {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-left: 2rem;

        .logo {
            height: 2.5rem;
            width: 2.5rem;
            margin-top: 0.3rem;
        }

        .brand-name {
            margin-left: 0.8rem;
            font-weight: 800;
            font-size: 1.2rem;
        }

        .tools-link {
            padding-left: 3rem;
            text-decoration: none;
            font-weight: 500;
            cursor: pointer
        }
    }

    .dark-mode-toggle {
        cursor: pointer;
        padding-right: 2rem;
        padding-top: 1.3rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:center;

        @media only screen and (max-width: 320px) {
            display: none;
        }

        .dark-mode-text {
            margin-left: 0.2em;
            @media only screen and (max-width: 800px) {
                display: none;
            }
        }
    }
}