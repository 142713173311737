.sticky {
    position: sticky;
    top: 0;
    z-index: 2;
}

.menu-container {
    display: flex;
    color: white;
    padding: 1rem 0;
    font-weight: 500;
    font-size: 1rem;
    flex-direction: column;
    justify-content: space-between;
    min-height: 20vh;


    


    .menu-item {

        &:hover {
            font-weight: 600;
        }
        @media only screen and (max-width: 800px) {
            margin-top: 0.6rem;
        }
    }

    .type-text {
        margin-left: 0.8rem;
    }

    .first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 5rem;

        @media only screen and (max-width: 800px) {
            flex-direction: column;
            align-items: baseline;
        }
    }

    .second-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 5rem;

        @media only screen and (max-width: 800px) {
            flex-direction: column;
            align-items: baseline;
        }
    }
}

.main-container {
    display: flex;
    flex-direction: column;
    color: white;

    .banner {
        min-height: 40vh;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media only screen and (max-width: 800px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }

        .first-column {
            display: flex;
            max-width: 45vw;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 2rem;

            @media only screen and (max-width: 800px) {
                max-width: 80vw;
                margin-top: 1rem;
                padding: 0 2rem;
            }
        }

        .second-column {
            max-width: 45vw;
            font-size: 1.8rem;
            font-weight: 600;

            @media only screen and (max-width: 800px) {
                margin-bottom: 2rem;
                max-width: 70vw !important;
                padding-left: 1.5rem !important;
                margin-left: 0.5rem;
            }

            .convert-text {
                margin-right: 2rem;
                font-size: 1.4rem;
                font-weight: 700;

                @media only screen and (max-width: 800px) {
                    font-size: 1.2rem !important;
                }
            }

            .dropdown-list {
                margin-right: 2rem;
                width: 5.5rem;
                height: 2rem;
                background: none;
                border: 0.1rem solid white;
                color: white;
                cursor: pointer;

                @media only screen and (max-width: 800px) {
                    min-width: 4rem !important;
                    display: flex;
                    flex-direction: row;
                }

                .small-text {
                    padding-left: 0.2rem;
                    font-weight: 600;
                    font-size: 0.9rem;
                }
            }
        }

        .file-type {
            font-size: 1.4rem;
            font-weight: 700;

            @media only screen and (max-width: 800px) {
                font-size: 1.2rem !important;
            }
        }

        .banner-text {
            font-size: 1rem;
            min-width: 45vw;
            font-weight: 500;
            text-align: justify;
            text-justify: inter-character;

            @media only screen and (max-width: 800px) {
                font-size: 0.9rem !important;
            }
        }
    }

    .file-upload-section {
        margin-top: 1rem;
        align-self: center;
    }

    .nav-menu {
        margin-top: 2.2rem;
    }

    .features-box {

        font-size: 0.9rem;

        .features-first-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
    
            @media only screen and (max-width: 800px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
            }
    
            .content-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                max-width: 30vw;
                padding: 1.5rem 1.5rem;
    
                @media only screen and (max-width: 800px) {
                    max-width: 80vw;
                }
    
    
                .heading {
                    font-weight: 600;
                    margin-top: 0.8rem;
                }
    
                .description {
                    min-width: 45vw;
                    font-weight: 300;
                    text-align: justify;
                    text-justify: inter-character;
                }
            }
        }
    }
    
}