.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100vw;
    height: 5vh;
    background-color: #212529;
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    border-top: 1px dotted #bfc0c0;

    .copyright {
        padding-left: 2rem;
    }

    .language-toggle {
        padding-right: 2rem;
    }
}
