.menu-container {
    display: flex;
    color: white;
    padding: 2rem 0;
    font-weight: 500;
    font-size: 1.1rem;
    flex-direction: column;
    justify-content: space-between;
    min-height: 20vh;


    @media only screen and (max-width: 800px) {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 32vh;
        padding-top: 1.2rem;
        font-weight: 400;
        font-size: 0.8rem;
    }

    .menu-item {

        &:hover {
            font-weight: 600;
        }

        @media only screen and (max-width: 800px) {
            margin-top: 0.6rem;
        }
    }

    .type-text {
        margin-left: 0.8rem;
    }

    .first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 5rem;

        @media only screen and (max-width: 800px) {
            flex-direction: column;
            align-items: baseline;
        }
    }

    .second-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 5rem;

        @media only screen and (max-width: 800px) {
            flex-direction: column;
            align-items: baseline;
        }
    }
}

.main-container {
    display: flex;
    flex-direction: column;
    color: white;

    .formats-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        align-self: center;
        width: 100vw;

        .file-type-heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            font-size: 1.2rem;
            padding: 1rem 0;

            .file-type-heading-text {
                margin-left: 0.6rem;
            }
        }

        .file-types-list {
            padding: 0.5rem 2rem 2rem 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;

            .item {
                padding: 0.2rem;
                font-weight: 400;
                font-size: 1rem;

                &:hover {
                    font-weight: 600;
                }
            }
        }
    }

    .banner {
        min-height: 40vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 800px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        .first-column {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 2rem;
            max-width: 45vw;

            @media only screen and (max-width: 800px) {
                max-width: 80vw !important;
                margin-top: 1rem;
                padding: 0 2rem;
            }
        }

        .second-column {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-start;
            max-width: 45vw;

            @media only screen and (max-width: 800px) {
                max-width: 70vw !important;
                margin-top: 1rem;
                padding-left: 1.5rem !important;
                margin-left: 0.5rem;
            }

            .dropdown-list {
                margin-right: 2rem;
                width: 5.5rem;
                height: 2rem;
                background: none;
                border: 0.05rem solid white;
                color: white;
                cursor: pointer;

                &:hover {
                    border: 0.1rem solid white;
                }

                @media only screen and (max-width: 800px) {
                    min-width: 4.8rem !important;
                    display: flex;
                    flex-direction: row;
                }

                .small-text {
                    font-size: 0.8rem;
                }

            }

        }

        .file-type {
            font-size: 1.4rem;
            font-weight: 700;

            @media only screen and (max-width: 800px) {
                font-size: 1.2rem !important;
            }
        }

        .banner-text {
            font-size: 1rem;
            min-width: 45vw;
            font-weight: 500;
            text-align: justify;
            text-justify: inter-character;

            @media only screen and (max-width: 800px) {
                font-size: 1rem !important;
            }
        }
    }

    .file-upload-section {
        margin-top: 1rem;
        align-self: center;
    }

    .files-definition-container {
        display: flex;
        height: 33vh !important;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        padding: 1.5rem 0;
        width: 100vw;
        color: #495057;
        border-bottom: 1px dotted #bfc0c0;

        @media only screen and (max-width: 800px) {
            height: 80vh !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }

        .first-file-definition {
            max-width: 43vw;
            -webkit-box-shadow: 0px 1px 3px 1px rgba(73, 80, 87, 0.2);
            -moz-box-shadow: 0px 1px 3px 1px rgba(73, 80, 87, 0.2);
            box-shadow: 0px 1px 3px 1px rgba(73, 80, 87, 0.2);
            height: inherit;
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 800px) {
                min-width: 90vw !important;
            }

            .file-type-icon {
                height: 2.5rem;
                width: 2.5rem;
                align-self: center;
            }

            .file-definition-para {
                font-size: 0.8rem;
                font-weight: 500;
                padding: 0 0.9rem;
                text-align: center;
                text-justify: inter-character;
                letter-spacing: 0.5px;
            }
        }

        .second-file-definition {
            max-width: 43vw;
            -webkit-box-shadow: 0px 1px 3px 1px rgba(73, 80, 87, 0.2);
            -moz-box-shadow: 0px 1px 3px 1px rgba(73, 80, 87, 0.2);
            box-shadow: 0px 1px 3px 1px rgba(73, 80, 87, 0.2);
            height: inherit;
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 800px) {
                min-width: 90vw !important;
                margin-top: 1.2rem;
            }

            .file-type-icon {
                height: 2.5rem;
                width: 2.5rem;
                align-self: center;
            }

            .file-definition-para {
                font-size: 0.8rem;
                font-weight: 500;
                text-align: center;
                text-justify: inter-character;
                padding: 0 0.9rem;
                letter-spacing: 0.5px;
                text-shadow: #495057;
            }
        }
    }
}